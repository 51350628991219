import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

const FormInputTextArea = React.forwardRef((props, ref) => {
  const {
    type,
    name,
    label,
    error,
    icon,
    required,
    className,
    onChange,
    labelClassName,
    onSetFieldValue,
    containerClassName,
    rows,
    ...rest
  } = props;

  const handleOnChange = (e) => {
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, e?.target?.value);
      return;
    }
    if (typeof onChange === 'function')
      onChange((prev) => ({
        ...prev,
        [name]: e?.target.value,
      }));
  };

  return (
    <div>
      <div className="w-full relative">
        <div
          className={cn(
            'form-container transition duration-300 ease-in-out',
            containerClassName,
            {
              'form-container': !error,
              'form-container-error': error,
            }
          )}
        >
          {label && (
            <label
              className={cn('form-label', labelClassName)}
              htmlFor={rest?.id ?? name}
            >
              {label} {required ? <span className="text-red-500">*</span> : ''}
            </label>
          )}
          <div className="relative">
            <textarea
              id={rest?.id ?? name}
              type="text"
              autoComplete="none"
              rows={`${rows}`}
              name={name}
              onChange={handleOnChange}
              className={cn(
                'transition duration-300 ease-in-out',
                className,
                {
                  'form-input': !error,
                  'form-input-error': error,
                },
                {
                  'pl-3 pr-8': icon,
                }
              )}
              required={required}
              {...rest}
              ref={ref}
            />
            <>
              {icon && (
                <span className="absolute right-2 h-full top-0 flex items-center">
                  {icon}
                </span>
              )}
            </>
          </div>
        </div>
        {error && <small className=" form-error-badge">{error ?? ''}</small>}
      </div>
    </div>
  );
});

FormInputTextArea.defaultProps = {
  label: 'Name',
  containerClassName: '',
  labelClassName: '',
  className: '',
  required: false,
  type: 'text',
  error: false,
  icon: false,
  onSetFieldValue: false,
  onChange: false,
  rows: 4
};

FormInputTextArea.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  onSetFieldValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  rows: PropTypes.number
};

export default React.memo(FormInputTextArea);
